// pages/signup.js

import React from "react";

const SignUp = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "centre",
                alignItems: "centre",
                height: "100vh",
            }}
        >
            <h1>Sign Up</h1>
        </div>
    );
};

export default SignUp;