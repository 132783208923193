import React, { useState, useEffect } from "react";
import { Nav, NavLink, Bars, Times, NavMenu, NavBtn, NavBtnLink, DrawerMenu, DrawerLink, } from "./NavbarElements";
import { assets } from "../../assets/assets";
import "./Navbar.css";

const Navbar = () => {

    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };


    const [scrollNav, setScrollNav] = useState(false);

    const changeNav = () => {
        if (window.scrollY >= 80) {
            setScrollNav(true);
        } else {
            setScrollNav(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeNav);
        return () => {
            window.removeEventListener('scroll', changeNav);
        };
    }, []);

    return (
        <>
            <Nav scrollNav={scrollNav}>
                <NavLink to="/">
                    <img src={assets.eg_logo_text} alt="" className="logo" />
                </NavLink>
                <div onClick={() => handleDrawerToggle(!mobileOpen)}>
                    {
                        mobileOpen ? <Times /> : <Bars />
                    }
                    <NavBtnLink to="https://play.google.com/store/apps/details?id=com.eggold" target="blank" id="buy_gold">Buy 24k Gold</NavBtnLink>
                </div>

                <NavMenu>
                    <NavLink to="/">Home</NavLink>
                    <NavLink to="/about">About Us</NavLink>
                    <NavLink to="/#features">Features</NavLink>
                    {/* <NavLink to="/annual">Resources</NavLink> */}
                    {/* <NavLink to="/team">Teams</NavLink>
                    <NavLink to="/blogs">Blogs</NavLink>
                    <NavLink to="/sign-up">Sign Up</NavLink> */}
                </NavMenu>
                <NavBtn>
                    <NavBtnLink to="https://play.google.com/store/apps/details?id=com.eggold" target="blank">Buy 24k Gold</NavBtnLink>
                </NavBtn>
            </Nav>

            <DrawerMenu open={mobileOpen} onClick={handleDrawerToggle}>
                <DrawerLink to="/">Home</DrawerLink>
                <DrawerLink to="/about">About Us</DrawerLink>
                <DrawerLink to="/#features">Features</DrawerLink>
                {/* <DrawerLink to="/annual">Resources</DrawerLink> */}
                {/* <DrawerLink to="/team">Teams</DrawerLink>
                <DrawerLink to="/blogs">Blogs</DrawerLink>
                <DrawerLink to="/sign-up">Sign Up</DrawerLink> */}
            </DrawerMenu>
        </>
    );
};

export default Navbar;
