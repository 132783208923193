// pages/blogs.js

import React from "react";

const Blogs = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "centre",
                alignItems: "centre",
                height: "100vh",
            }}
        >
            <h1>Welcome to GeeksforGeeks Blogs</h1>
        </div>
    );
};

export default Blogs;