import styled from 'styled-components';
import { NavLink as Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';

export const Nav = styled.nav`
background: ${({ scrollNav }) => ('linear-gradient(120deg, rgba(252,226,124,1) 0%, rgba(255,207,30,1) 100%)')};
      ${'' /* background: ${({ scrollNav }) => (scrollNav ? 'rgba(252,226,124,1)' : 'linear-gradient(120deg, rgba(252,226,124,1) 0%, rgba(255,207,30,1) 100%)')}; */}
      height: 80px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem calc((50vw - 1000px) / 10);
      z-index: 10;
      position: ${({ scrollNav }) => (scrollNav ? 'fixed' : 'absolute')};
      width: ${({ scrollNav }) => (scrollNav ? '100%' : 'calc(100% - 120px)')}; /* Adjust width to account for margins */
      border-radius: ${({ scrollNav }) => (scrollNav ? '0' : '50px')};
      margin-top: ${({ scrollNav }) => (scrollNav ? '0' : '20px')};
      margin-left: ${({ scrollNav }) => (scrollNav ? '0' : '60px')}; /* Add left margin */
      margin-right: ${({ scrollNav }) => (scrollNav ? '0' : '60px')}; /* Add right margin */
      transition: 0.9s all ease;

  @media screen and (max-width: 768px) {
      background: ${({ scrollNav }) => ('linear-gradient(120deg, rgba(252,226,124,1) 0%, rgba(255,207,30,1) 100%)')};
      ${'' /* background: ${({ scrollNav }) => (scrollNav ? 'rgba(252,226,124,1)' : 'linear-gradient(120deg, rgba(252,226,124,1) 0%, rgba(255,207,30,1) 100%)')}; */}
      height: 80px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem calc((50vw - 1000px) / 10);
      z-index: 10;
      position: ${({ scrollNav }) => (scrollNav ? 'fixed' : 'absolute')};
      width: ${({ scrollNav }) => (scrollNav ? '100%' : 'calc(100% - 40px)')}; /* Adjust width to account for margins */
      border-radius: ${({ scrollNav }) => (scrollNav ? '0' : '50px')};
      margin-top: ${({ scrollNav }) => (scrollNav ? '0' : '20px')};
      margin-left: ${({ scrollNav }) => (scrollNav ? '0' : '20px')}; /* Add left margin */
      margin-right: ${({ scrollNav }) => (scrollNav ? '0' : '20px')}; /* Add right margin */
      transition: 0.3s all ease;
  }

`;

export const NavLink = styled(Link)`
  color: #004E42;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1.5rem;
  height: 100%;
  cursor: pointer;
  font-weight: 600;

  &.active {
    color: #004E42;
  }
`;

export const Bars = styled(FaBars)`
  display: none;
  color: #004E42;
  margin-top: 5px;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const Times = styled(FaTimes)`
  display: none;
  color: #004E42;
  margin-top: 5px;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: 100px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 24px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavBtnLink = styled(Link)`
  border-radius: 50px;
  background: #004E42;
  padding: 14px 22px;
  color: #EBD38C;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  font-weight: 600;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606;
  }
`;

export const DrawerMenu = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    background: #FCE27C;
    position: fixed;
    top: 80px;
    width: 100%;
    height: calc(100% - 80px);
    padding: 20px;
    transition: 0.3s ease-in-out;
    transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
    z-index: 999999 !important;
  }
  
`;

export const DrawerLink = styled(Link)`
  color: #004E42;
  text-decoration: none;
  padding: 15px;
  text-align: left;
  font-weight: 600;

  &:hover {
    color: #333333;
  }
`;



