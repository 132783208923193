import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, } from "react-router-dom";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Events from "./components/Events/Events";
import Annual from "./components/Annual/Annual";
import Teams from "./components/Teams/Teams";
import Blogs from "./components/Blogs/Blogs";
import SignUp from "./components/SignUp/SignUp";
import PrivacyPolicy from "./components/Policy/PrivacyPolicy";
import RefundPolicy from "./components/RefundPolicy/RefundPolicy";
import TermsAndConditions from "./components/TermsAndConditions/TermsAndConditions";
import Layout from "./components/Layout/Layout";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Aos from "aos";
import "aos/dist/aos.css";

function App() {

    useEffect(() => {
        Aos.init();
    }, [])    

    return (
        <Router>
            <ScrollToTop />
            <Layout>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/events" element={<Events />}/>
                    <Route path="/annual" element={<Annual />}/>
                    <Route path="/team" element={<Teams />} />
                    <Route path="/blogs" element={<Blogs />} />
                    <Route path="/sign-up" element={<SignUp />}/>
                    <Route path="/privacy-policy" element={<PrivacyPolicy />}/>
                    <Route path="/refund-policy" element={<RefundPolicy />}/>
                    <Route path="/terms-and-conditions" element={<TermsAndConditions />}/>
                </Routes>
            </Layout>
        </Router>
    );
}

export default App;

