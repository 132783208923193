import React from "react";
import "./Footer.css";
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaYoutube,  } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { assets } from "../../assets/assets";
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import { Link } from "react-router-dom";

const Footer = () => {

    return(
        <>
            <div className="footer" id="footer">
                <div className="footer-content">
                    <div className="footer-content-left">
                        <img src={assets.eg_logo_footer} alt="" className="logo-footer" style={{width: '150px'}} />
                        <p> <PinDropOutlinedIcon /> C-26(D), Pari Creation, JPR Webs RICCO Industrial Area - Jaipur, Rajasthan - 302017</p>
                        <div className="footer-social-icons">
                            <ul className="social-icons">
                                <li className="social-icons-media" style={{marginLeft: '0px'}}><FaFacebookF /></li>
                                <li className="social-icons-media"><FaInstagram /></li>
                                <li className="social-icons-media"><FaLinkedinIn /></li>
                                <li className="social-icons-media"><FaXTwitter /></li>
                                <li className="social-icons-media"><FaYoutube /></li>
                            </ul>
                        </div>
                        <div className="footer-social-icons">
                            <ul className="social-icons">
                                <li style={{marginLeft: '0px'}}> <a href="https://play.google.com/store/apps/details?id=com.eggold" target="blank" > <img src={assets.google} alt="" /> </a> </li>
                                <li style={{marginLeft: '12px'}}><img src={assets.app_store} alt="" /></li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer-content-center">
                        <h2>EG-GOLD</h2>
                        <ul>
                            <li><Link to="#home"> Home </Link></li>
                            <li><Link to="/about">About Us </Link> </li>
                            <li><Link to="#features">Features </Link> </li>
                            {/* <li>Daily Savings</li>
                            <li>Round-offs</li>
                            <li>Careers</li> */}
                        </ul>
                    </div>
                    <div className="footer-content-right">
                        <h2>Explore</h2>
                        <ul>
                            {/* <li>What is Digital Gold?</li>
                            <li>Check Gold Price</li> */}
                            <li> <Link to="#faq"> FAQ’s </Link></li>
                            {/* <li> <Link to="#blog"> Blogs </Link></li> */}
                            {/* <li>Instant Loan</li> */}
                        </ul>
                    </div>
                    <div className="footer-content-right">
                        <h2>Contact us</h2>
                        <ul>
                            {/* <li>Contact Support</li> */}
                            <li><Link to="/privacy-policy"> Privacy Policy</Link></li>
                            <li><Link to='/refund-policy'> Refund Policy </Link></li>
                            <li><Link to="/terms-and-conditions"> Terms And Conditions </Link></li>
                            {/* <li>ISMS Policy</li> */}
                            {/* <li>Terms of Use</li> */}
                        </ul>
                    </div>
                </div>
                <hr />
                <p className="footer-copyright">Copyright 2024 © EG Gold All Rights Reserved. </p>
            </div>
        </>
    )
}

export default Footer;
