// import { useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
// const ScrollToTop = () => {
//   const { pathname } = useLocation();
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);
//   return null;
// };
// export default ScrollToTop;

import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
const ScrollToTop = () => {
  const { pathname, hash } = useLocation();
  const navigate=useNavigate();
  useEffect(() => {
    if (hash) {
      console.log(hash)
      const element = document.getElementById(hash.substring(1));
      if(element===null && hash==="#faq"){
        navigate('/#faq')
      }
      else if (element===null && hash==="#blog"){
        navigate('/#blog')
      }
      else if (element===null && hash==="#home"){
        navigate('/#home')
      }
      if (element) {
        element.scrollIntoView({ behavior: 'auto', block: 'start' });
      }
    } else {
      const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
      const isFirefox = typeof InstallTrigger !== 'undefined';
      if (isChrome) {
        window.scrollTo(0, 0);
      } else if (isFirefox) {
        document.documentElement.scrollIntoView({ behavior: 'auto', block: 'start' });
      } else {
        window.scrollTo(0, 0);
      }
    }
  }, [pathname, hash]);
  return null;
};
export default ScrollToTop;
