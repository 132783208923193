// pages/annual.js

import React from "react";

const AnnualReport = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "centre",
                alignItems: "centre",
                height: "100vh",
            }}
        >
            <h1>Annual Report</h1>
        </div>
    );
};

export default AnnualReport;