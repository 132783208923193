// pages/index.js
import React, { useState, useEffect } from 'react';
import "./Home.css";
import { Link } from "react-router-dom";
import { assets } from "../../assets/assets";
import Slider from "react-slick";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { Button, CardActionArea } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useTheme, useMediaQuery } from '@mui/material';
import axios from 'axios';



const Home = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [expanded, setExpanded] = useState("panel1");
    const [img, setImg] = useState("img1");
    const [tab, setTab] = useState("buy");
    const [amountBuy, setAmountBuy] = useState(null);
    const [goldBuy, setGoldBuy] = useState(null);
    const [sellPrice, setSellPrice] = useState(null);
    const [price,setPrice]=useState(null)
    const [amount, setAmount] = useState(100);
    const [gold, setGold] = useState(1);
    
    useEffect(() => {
      fetchGoldPrice();
      const intervalId = setInterval(fetchGoldPrice, 300000); // 5min
      return () => clearInterval(intervalId);
    }, []);
    const fetchGoldPrice = async () => {
      try {
        const response = await axios.get(
          "https://a.suncrypto.co/gold_new/get_rate.php"
        );
        setPrice(response.data.buy_price.price)
        setAmountBuy(
          response.data.buy_price.price + response.data.buy_price.price * 0.03
        );
        setGoldBuy(
          response.data.buy_price.price + response.data.buy_price.price * 0.03
        );
        setSellPrice(response.data.sell_price.price);
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    // const [faqs, setFaqs] = useState("faq1");
    // const [buttonClick, setButtonClick] = useState(4);

    const [value, setValue] = React.useState(0);

    

    const handleChange = (event, newValue) => { setValue(newValue); };

    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <Box sx={{ p: 4 }}>{children}</Box>}
            </div>
        );
    }

    CustomTabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const [visibleCount, setVisibleCount] = useState(4);
    const [expandedFaq, setExpandedFaq] = useState('panelF0');
    const accordions = [
        { title: '1. What is the eligibility criteria for SafeGold?', content: 'Any resident Indian with a valid PAN Card/Form 60/ Aadhar Card and a Bank Account in their name can enroll for SafeGold.' },
        { title: '2. Do you charge for storage of my gold?', content: 'SafeGold offers free storage for up to 5 years, after which we reserve the right to charge a yearly nominal fee for the storage of your gold, which would be in the range of around 0.3 - 0.4% (per annum) of your gold balance.' },
        { title: '3. Is it mandatory to upload my PAN card?', content: 'It is not mandatory to upload your PAN card, however, you may be prompted to do so if you wish to purchase gold that is worth more than ₹ 500.' },
        { title: '4. Why is KYC needed?', content: 'The safety and security of your gold is always our highest priority, and we are continuously working to improve these aspects. We have upgraded the SafeGold experience to prevent the misuse of your account or unauthorized access to your gold. This will ensure a much safer experience for you, while also allowing you to buy or sell more gold than before.' },
        { title: '5. What is the Purity of Gold offered under SafeGold?', content: 'SafeGold offers 24 Karat Gold of 9999 purity (99.99% pure)' },
        { title: '6. What are the various tenures available under SafeGold?', content: 'Customer is not obligated to make any fixed or periodic payments unless enrolled in a Gold Savings Plan.' },
        { title: '7. What is the minimum & maximum amount needed to buy gold on SafeGold?', content: 'The minimum amount of purchase is ₹ 10. There is no limit on the maximum amount as long as KYC requirements are complied with. In case of Gold Savings Plan, a customer has to invest in at least ₹ 100 worth of gold.' },
        { title: '8. How can I get my holding statement?', content: 'An online statement will be available at all times which you can view online by clicking on Transaction History link. A quarterly balance statement will also be sent to customers through email.' },
        { title: '9. Can I have a joint account for this plan?', content: 'No, you cannot have a joint account for SafeGold.' },
        { title: '10. Is nomination mandatory for SafeGold?', content: 'Nomination norms will be followed as per the distributors standards for their product.' },
        { title: '11. At what price is gold sold/ bought from customers?', content: 'The Company will publish a live buy-sell quote for customers which will be derived from the wholesale bullion market plus a commission to cover operating costs. The actual purchase price paid by the customer will always be transparently published so the customer has a chance to compare with all other sources before entering into a transaction.' },
        { title: '12. Why is the Buy Price different from the Sell Price?', content: 'SafeGold buys international grade bullion from institutional suppliers and sells this to consumers. When customers sell gold back to us, we sell it back to institutional participants in the wholesale bullion market, where the buyback rate is lower than the selling rate – this is referred to as the buy-sell spread.  In addition to the buy-sell spread in the commercial bullion market, GST is added to the buy price, but it is not levied on any sell transactions. Further, there are charges incurred on the payment gateway, trustee, insurance and custodian fees which also contribute to the difference in buy and sell price.' },
        { title: '13. Is the gold associated with my accumulations kept in safe custody?', content: 'Yes, the gold associated with the accumulations credited in your account is kept in safe custody with a Custodian in accordance with the terms of the offer mentioned in the product brochure. Only reputed firms such as Sequel Logistics and Brinks will be used as a custodian.' },
        { title: '14. Who will insure the physical vault?', content: 'SafeGold has partnered with the global market leader in secure logistics and vault services- Brinks, to store your gold. As part of their duty, the secure storage provider takes out a comprehensive insurance policy, which includes the cover on your gold. Over and above that, SafeGold has insurance cover for the period where gold is in transit, when the gold leaves the vault. We review our risk & insurance policies regularly, to ensure that we are covered for additional or specific risks.' },
        { title: '15. Who will manage consumer complaints?', content: 'Distributors will provide the first level of customer support. The Company will be available to satisfy any issues/ questions that may be escalated. You may write to us at care@safegold.in or call our Customer Service team at 8881000800.' },
        { title: '16. Will the customer need to show any identity proof at the time of taking the physical gold coins?', content: 'A government ID (for example aadhar card) is mandatory for identity verification during the collection of gold delivery of 5 grams or greater as part of our security process. No ID is required for delivery of less than 5 grams.' },
        { title: '17. Is there any regulator or Grievance redressal mechanism?', content: 'SafeGold is neither a Collective Investment Scheme nor a Deposit. Therefore we do not fall under the purview of SEBI or RBI. However, to ensure that the customers interest is always prioritized, a Security Trustee has been appointed for SafeGold. For any grievances the customer can approach the Security Trustee.' },
        { title: '18. What will happen to the gold associated with accumulations in my account, in the unlikely event of the Company going into liquidation?', content: 'The gold associated with accumulations in your account is separate from the other assets of the Company. An independent trustee appointed by the Company will act for and on behalf of the customers. The gold purchased by the Company associated with the accumulation in customer accounts is transferred to the Custodian appointed by the Company for the product. When the customer asks for delivery, the requisite quantity of gold will be removed from the Custodian and delivered to the customer through a reputed courier service. Since the Security Trustee has a charge over the gold over other commercial creditors till the time of fulfillment and the gold is separate from the other assets of the Company, any unlikely adverse event happening to Company should not affect the gold associated with the accumulation in your account. Further, the title of the gold clearly rests with the customer and the physical gold stored with the Custodian on account of SafeGold customers is not an asset of the Company in any way.' },
        { title: '19. Which are the agencies which certify Gold in India?', content: `The Government of India has identified the Bureau of Indian Standard as the sole agency in India to operate the BIS Hallmarking Scheme. The BIS Hallmarking Scheme has been aligned with International criteria on hallmarking (Vienna Convention 1972). A Hallmark, consists of five components i.e. BIS Mark, the Fineness number (corresponding to given carratage), Assaying and Hallmarking Centres Mark, Jewellers identification Mark and year of Marking denoted by a code letter and decided by BIS (e.g. code letter 'A' was approved by BIS for year 2000, 'B' being used for the year 2001 and 'C' for 2002 and 'J' for 2008). The marking is done either using punches or laser marking machine. Some manufacturers also issue Assay Certification which can be obtained through Assay Offices. Assay offices are institutions set up to assay (test the purity of) precious metals, in order to protect consumers. All coins and jewellery offered SafeGold are assay certified.` },
        { title: '20. Is there any lock-in period for this plan?', content: `No, there is no lock-in period, for security reasons, you need to verify your Aadhar details for selling gold if you intend to sell it within the 3 to 7 days of purchase date. When it comes to Gold Savings Plans, you can decide the period and frequency you'd like to opt for.` },
        { title: '21. What is the benchmark for the Price of SafeGold? Can MCX be taken as a benchmark?', content: `There is no benchmark for comparing the price of SafeGold. MCX cannot be considered as a benchmark, since there are many differences between SafeGold and MCX. MCX gives the prices of a futures contract which doesn't give the landed prices by factoring in cost of logistics, insurance, safekeeping, etc. MCX also does not include pan India delivery, and based on the contract it gives delivery at only a few locations like Mumbai, Ahmedabad, Delhi, etc. SafeGold gives delivery across the country, and includes the cost of storage, insurance, trustee fees and gives you the landed, complete price of Gold. Also the gold price quoted in newspapers and on various websites is the wholesale price in multiples of 1kg of gold. SafeGold price is valid for fractional grams and therefore is not directly comparable.` },
        { title: '22. Who is Digital Gold India Private Limited?', content: `Digital Gold India Private Limited (Company) is an institutionally backed company, primarily owned by Invent Advisors Private Limited (Invent). The World Gold Council (India) Private Limited, as well as various other investor funds, hold minority stakes in the Company. SafeGold is the retail brand of the Company.` },
        { title: '23. Can someone else redeem the physical gold coins on my behalf?', content: 'Only in case of death of the applicant, the legal heir will be able to collect the gold coins by showing the proof of identity of along with the original / notarized copy of the death certificate and proof that the person is the legal heir of the applicant.' },
        { title: '24. What if the customer dies during the tenure of the plan?', content: `The legal heir of the customer can claim the customer's account by following the process detailed above. Thereafter, they can access the customer's account, including selling the gold balance or requesting for delivery of the gold.` },
        { title: '25. How can one change their registered mobile number on SafeGold?', content: 'If you would like to change your registered mobile number on SafeGold, you will need to submit your KYC details on our customer care email id at care@safegold.in and we will update you.' },
        { title: '26. What happens when payment is deducted through ones credit card/debit card but the gold is not credited in ones SafeGold balance?', content: `If the payment has been debited from your debit/credit card and the gold in your SafeGold balance has not been credited, you will need to contact our customer care team at care@safegold.in and we will resolve your issue.` },
    ];
    const handleChangeF = (panel) => (_event, isExpanded) => {
        setExpandedFaq(isExpanded ? panel : false);
    };
    const handleLoadMore = () => {
        setVisibleCount((prevCount) => prevCount + 4);
    };

    const [visibleCount01, setVisibleCount01] = useState(4);
    const [expandedFaq01, setExpandedFaq01] = useState('panelF0');
    const accordions1 = [
        { title: '1. What is the eligibility criteria for SafeGold?', content: 'Any resident Indian with a valid PAN Card/Form 60/ Aadhar Card and a Bank Account in their name can enroll for SafeGold.' },
        { title: '2. Do you charge for storage of my gold?', content: 'SafeGold offers free storage for up to 5 years, after which we reserve the right to charge a yearly nominal fee for the storage of your gold, which would be in the range of around 0.3 - 0.4% (per annum) of your gold balance.' },
        { title: '3. Is it mandatory to upload my PAN card?', content: 'It is not mandatory to upload your PAN card, however, you may be prompted to do so if you wish to purchase gold that is worth more than ₹ 500.' },
        { title: '4. Why is KYC needed?', content: 'The safety and security of your gold is always our highest priority, and we are continuously working to improve these aspects. We have upgraded the SafeGold experience to prevent the misuse of your account or unauthorized access to your gold. This will ensure a much safer experience for you, while also allowing you to buy or sell more gold than before.' },
        { title: '5. What is the Purity of Gold offered under SafeGold?', content: 'SafeGold offers 24 Karat Gold of 9999 purity (99.99% pure)' },
    ];
    const handleChangeF1 = (panel) => (_event, isExpanded) => {
        setExpandedFaq01(isExpanded ? panel : false);
    };
    const handleLoadMore1 = () => {
        setVisibleCount01((prevCount) => prevCount + 4);
    };

    const [visibleCount02, setVisibleCount02] = useState(4);
    const [expandedFaq02, setExpandedFaq02] = useState('panelF0');
    const accordions2 = [
        { title: '1.  What is Gold leasing?', content: `Gold leasing is a method by which you can lease your gold metal (held through digital platforms), to jewellers and ornament manufacturers (Lessee), who will utilise the gold metal as part of their working capital. After a fixed term, the Lessee will return your metal along with a lease rental payment. In concept, this is not very different from you owning an asset like a house or a car and renting it out to another user who pays a rental for using your asset.  All parts of the lease are denominated and carried out in grams of physical gold.` },
        { title: '2. How does it work? What are the steps involved?', content: `Choose the jeweller from the listed options.Purchase gold worth 0.5 gm or more to commit to the lease (you can also utilise your existing SafeGold balance for this).Complete your PAN verification (in case you haven’t already). Confirm the lease (you will be shown the jeweller, tenure and amount of gold you are committing)` },
        { title: '3. How do I participate in a gold lease?', content: 'In order to lease your gold, you’ll need to have a SafeGold account, and complete the steps above.' },
        { title: '4. How and when will I earn a yield on my gold?', content: 'Once you’ve committed your gold for a specific lease, the yield will be paid out to your SafeGold account (in the form of gold grams) every month. At the end of the lease, the last payment to your account will have both the principal gold amount as well as the yield added.' },
        { title: '5. Who is SafeGold? What role does SafeGold play in the lease?', content: `SafeGold is India’s leading digital gold platform, with partnerships across 60+ platforms, and over 20 million customers. We are India’s largest ecosystem of digital gold services, which includes buying and selling gold at live market rates, gold savings, and converting your digital gold to physical forms like gold jewellery, coins and bars. Read more about us on our here.<br>SafeGold facilitates the leasing of your gold savings in terms of onboarding & vetting the jewellers listed for lease options, including the security provided as collateral for the lease. We also physically provide the gold metal to the jeweller for their use. We ensure that yield payments are added to your account every month, and at the end of the lease tenure, add back the committed gold and yield to your account once the jeweller has closed the lease.` },
        { title: '6. Is the yield paid out in rupees or grams of gold? If in gold, how do I convert it to rupees?', content: `The yield is paid out in the form of grams of gold, which accrue to your SafeGold account. You can sell this gold at any time as per the prevalent rate, and the funds will be transferred to your bank account.` },
        { title: '7. I have physical gold in my house, can you lease that for me?', content: `At the moment, this product is only available for customers with digital gold purchased from SafeGold. We are working on enabling customers to lease out their physical gold lying at home soon!` },
        { title: '8. Who am I leasing out the gold to? Why do they lease my gold?', content: ` You are leasing out your gold to India’s community of retail jewellers - i.e. the very same folks from whom your family might have been buying jewellery for generations. But we don’t just allow anyone to list on the SafeGold platform - we provide a vetted list of jewellers with whom we have deep relationships, whose credibility and credit worthiness are established.<br>Jewellers need gold metal in the same way that any business needs working capital. For a jeweller, it makes more sense to lease gold metal directly, because otherwise they could be affected by price fluctuations if they utilise a rupee loan to purchase gold and then have to repay in rupees once the gold price goes up. ` },
        { title: '9. How do I choose between the lessees (jewellers) where I can lease my gold?', content: `You can choose between lease options based on details such as tenure and yield provided by different jewellers on the listing page.` },
        { title: '10. How is the yield rate decided? ', content: ` The yield rate is offered by the jeweller and shown to you. SafeGold may provide higher yields for returning customers or as a temporary offer from time to time, in addition to the yield offered by the jeweller.` },
        { title: '11.  When is the lease process complete? Do I start earning returns from the day I lease my gold?', content: `The lease shall continue during the tenure mentioned in the listing page, unless the jeweller chooses to close the lease at an earlier date, by settling the gold metal. You will earn a yield from the time that your gold is committed on a daily basis, which will accrue on a monthly basis to your digital gold account. ` },
        { title: '12. How do I exit a lease?', content: ` You will be able to exit a lease at the end of the tenure mentioned on the listing page or at an earlier date, if the jeweller chooses to close out the lease earlier. The typically tenure for a lease is 3-6 months.` },
        { title: '13. Are there any global examples of this product?', content: `This is a completely innovative product, offered for the first time in the world by SafeGold. We are proud to offer this product in India first, given the rich tradition of gold as an investment class and one of the largest industries in the country.` },
        { title: '14. Is a gold lease part of the gold monetisation scheme?', content: `No, the gold lease is not part of the gold monetisation scheme. ` },
        { title: '15. Can there be circumstances in which the lease gets cancelled?', content: ` Yes, a jeweller can close out a lease earlier, in which case your gold, along with the applicable yield calculated up to the date on which the lease is closed, shall be added back to yout account.` },
        { title: '16. Can I invest in more than one lease?', content: `Yes! There is no upper limit on the number of leases in which you can invest. ` },
        { title: '17.  Can I view the lease agreement before investing in my gold?', content: `You can view the terms and conditions governing the lease here` },
        { title: '18.  Can I sell my gold while it is leased out?', content: `No, you will not be able to sell your gold while it has been committed to a lease, as it would have been removed from the vault and physically given to the jeweller.` },
        { title: '19. How can I calculate my returns in rupees rather than gms of gold?', content: ` SafeGold will send you a statement at the time of the leasing closing, which will contain details on the final yield in grams as well as rupees (calculated at the time of the statement being sent out).` },
        { title: '20. What are the minimum and maximum amounts that can be committed?', content: `The minimum commitment required is 0.5 gms and the maximum limit is 20 gms.` },
        { title: '21. Are there any KYC requirements while leasing gold?', content: `Your PAN is required for you to participate in the gold leasing offering. You will not be able to participate in the lease until you provide your PAN and complete your PAN verification process.` },
        { title: '22. Can an NRI participate in a gold lease?', content: `Subject to the use of an Indian mobile number, bank account and completion of PAN and bank verification checks, an NRI can participate in a gold lease. ` },
        { title: '23. Can minors participate in a gold lease?', content: `No, minors cannot participate in gold leases.` },
        { title: '24. Can I have a nominee? What happens in the event of my death?', content: `SafeGold does not have a nomination facility at present. However, in the event of your death, your heirs can get in touch with our customer support team to access your account. They will be required to submit documentation such as an affidavit (format will be provided), death certificate and proof of their status as your heir. ` },
        { title: '25.  Is this product legal?', content: `Yes, the gold metal lease is a legal product - SafeGold would not offer any products which are not legal.` },
        { title: '26.  How can you offer this product without an NBFC license?', content: `The gold metal lease is not denominated in rupees, nor does it repaid in the form of rupees. All transactions take place in the form of gold grams. Hence, this product does not require an NBFC license.` },
        { title: '27. What are the risks that I am exposed to when committing my gold? ', content: `  The key risks associated with the product can be viewed here.` },
        { title: '28.  What happens if the price of gold falls or if there’s a default? Will you guarantee my returns?', content: `SafeGold operates a platform which connects customers like you who wish to lease out your gold metal, to Jewellers who wish to enter into such a lease. While the Company will endeavour to protect your metal by enforcing collateral in an event of default by the jeweller, the Company does not guarantee your capital or any returns and there is no recourse available on the Company to you. ` },
        { title: '29. Is my gold safe?', content: `Vetting the credit worthiness of the jewellers listed on the platform .Obtaining security (in the form of collateral upto the specified collateral coverage) .Requiring additional collateral in the event of an increase in the price of gold .Enforcing the security collateral in the event of any default` },
        { title: '30.  How can I trust the the jeweller who is leasing the gold?', content: `We take your trust in our platform very seriously, and are extremely selective in which jewellers we allow to list on our platform. These are usually jewellers with whom we have deep relationships, where we have a higher confidence in their credibility.<br> In addition to this, the jewellers taking the lease provide a security for the lease, demonstrating their creditworthiness and commitment to repayment.` },
        { title: '31.  Is there any security over lease repayments? Do you have a contingency plan?', content: `Jewellers provide a specified collateral coverage of the value of gold they are leasing, which acts as a security for the lease. <br> In the event of default, SafeGold is authorised to enforce the bank guarantee, to ensure that funds are recovered to the greatest extent possible against the lease. ` },
        { title: '32. What is a bank guarantee? ', content: `A bank guarantee is an instrument through which a lending institution (such as a bank) promises to cover a loss if a leasee (such as a jeweller) defaults on a lease. This is usually enforceable by the lessor (eg. the one offering the metal to be leased) or an institution acting on behalf of the lessor.` },
        { title: '33.  What does a margin of a specified range such as 100-109% mean? ', content: `A margin of 100-109% means that the security provided by the Lessor (i.e. Jeweller) is equivalent to a value within the range of 100-109% of the gold metal taken on lease (calculated as per the price on the date of the lease, and thereafter updated based on daily pricing). ` },
        { title: '34.  How are you keeping my personal details safe?', content: ` Safegold has stringent security measures in place to protect the loss, misuse, and alteration of the information under our control. Whenever you change or access your account information, we offer the use of a secure server. Once your information is in our possession we adhere to strict security guidelines, protecting it against unauthorized access.` },
        { title: '35.  What if my account is hacked?', content: `SafeGold uses OTP verification to authenticate access to your account. In addition, the bank account to which proceeds from the sale of gold to your account is sent is verified against the PAN card provided by you, to prevent the misuse or fraudulent use of your account. Nevertheless, if you feel that your account has been compromised or improperly accessed, we request you to to inform us immediately by writing to care@safegold.in or call us on 8881000800.` },
        { title: '36.  Is gold leasing a regulated product? ', content: `No, gold leasing is not a regulated product.` },
        { title: '37.  Who can I complain to if I have a complaint? Will SEBI/ RBI help with grievance redressal?', content: `Gold Metal Leasing is not a regulated product and you do not have any regulatory recourse in the event you suffer any losses` },
        { title: '38. What is my tax liability for gold lease yields?', content: ` Taxation for digital gold is treated in the same manner as physical gold - i.e. as a capital asset, on which long term or short term capital gains tax is applicable, depending on when you sell the product. You will be able to see the transaction value for each yield paid into your account in your transaction history section.` },
        { title: '39. Are there any tax deductions on my gold lease yields?', content: ` No, there is no TDS or other tax deduction on your gold yields.` },
        { title: '40.  Does SafeGold report my lease yields to the tax authorities? Why do you need my PAN details?', content: `SafeGold collects customer PAN details as part of the identity verification process, and as part of the security process to ensure that the bank account to which sell funds are sent, matches the name on your PAN card. We are not required to report your yields or gold purchases to the tax authorities.` },
        { title: '41. Can I withdraw from my lease?', content: ` You can request us for a lease withdrawal/cancellation. Lease withdrawals are normally processed within 2 days but withdrawal is subject to market demand and could take significantly longer. We do not guarantee that a lease withdrawal will be completed within any stipulated period of time.` },
    ];
    const handleChangeF2 = (panel) => (_event, isExpanded) => {
        setExpandedFaq02(isExpanded ? panel : false);
    };
    const handleLoadMore2 = () => {
        setVisibleCount02((prevCount) => prevCount + 4);
    };

    const [visibleCount03, setVisibleCount03] = useState(4);
    const [expandedFaq03, setExpandedFaq03] = useState('panelF0');
    const accordions3 = [
        { title: '1. How do I sell my gold?', content: 'SafeGold provides a sell price quote on its website. Customers can choose to sell any amount starting with a minimum of ₹ 10 to a maximum of the amount of gold that they own. Proceeds will be sent within 4 working days to the customers bank account after deducting any applicable transaction charges (which will be disclosed in advance). The sell quote may be temporarily unavailable in the unlikely event of a disruption in the bullion market.' },
        { title: '2. Where do I get the proceeds when I sell my gold?', content: `When you sell your gold, you'll receive funds against it in a verified bank account in your own name. You can add your account details prior to the sale, which will be verified by us. Once the verification is complete, you'll receive the proceeds post the sale of your gold, within 4 working days.` },
        { title: '3. When will the sales proceeds be credited to my account?', content: 'It takes 4 working days to get money credited to your account once you have sold your gold. If the sell transaction has been made on Friday/Saturday or Sunday, then you will receive your sales proceeds by Tuesday of the following week.' },
        { title: '4. What happens if the sales proceeds are not credited to me within 4 days?', content: 'If the sales proceeds have not been received within 4 working days of the transaction, please email our customer service team at care@safegold.in with your related transaction details, and we shall resolve your issue.' },
        { title: '5. I am facing issues with bank account verification, what should I do?', content: 'In case you are facing any issues with Bank account verification, please reach out to our support team with a screenshot of the issue on care@safegold.in.' },
        { title: '6. Why does it take upto 4 days to receive the sell amount in my bank account?', content: 'We send funds to your account the following day after your sell transaction has been completed, and are subject to verification of your bank account details, if necessary. Due to the operational hours for banking transactions, as well as the time taken to transfer funds between banks, your funds may take up to 4 days to reach your bank account.' },
        { title: '7. Are there any KYC requirements while selling gold?', content: 'PAN is required when buying or sell gold worth more than Rs. 500. However, a 7 day wait period is applicable even after PAN has been provided. This 7 day wait period can be reduced to 3 days by completing the Aadhaar KYC verification.' },
        { title: '8. Why would I have to wait 7 days before I can sell any gold that I buy', content: 'To prevent fraudulent activity and to safeguard customers, any gold that is bought must be held for 7 days before it can be sold. Completing the Aadhaar KYC verification will reduce this 7 day wait period to 3 days for bought gold.' },
        { title: '9. What is Aadhaar KYC verification?', content: 'Aadhaar KYC verification is the process of securing your account by authenticating your Aadhaar. It involves triggering an OTP to your Aadhaar-linked mobile number and clicking a selfie that will be matched against the photo on your Aadhaar.' },
        { title: '10. Why is Aadhar KYC required for selling gold?', content: 'Aadhaar KYC is required only for those customers who wish to sell gold between 3 to 7 days of buying it.' },
        { title: '11. Can I reduce the 7-day wait period by doing Aadhaar KYC?', content: 'Yes, by doing Aadhaar KYC, you can reduce the 7-day wait period to 3 days.' },
        { title: '12. What happens if my PAN and Aadhaar details do not match?', content: 'If your PAN and Aadhaar details do not match, you will need to provide an upload of your PAN card and do Aadhaar KYC. Our support team will evaluate your case & reply within 3 business days.' },
        { title: '13. What if my PAN already exists in the system?', content: 'If your PAN already exists in the system, you can dispute it by providing a PAN upload and doing Aadhaar KYC. If verified, your PAN will be shifted to your profile along with Aadhaar.' },
        { title: '14. Is it mandatory to do Aadhaar KYC for buying or selling gold?', content: 'No, Aadhaar KYC is not mandatory, if your lifetime sell amount is less than Rs. 500, you can provide your bank account details/UPI VPA and the amount will be credited after 48 hours. However, if your cumulative sell is above Rs. 500, then providing PAN and a 7-day wait period will be mandatory.' },
        { title: '15. Can I reduce the wait period without doing Aadhaar KYC?', content: 'No, you cannot reduce the wait period without doing Aadhaar KYC. However, you can choose to wait for the 7-day period to end or do Aadhaar KYC to reduce the wait period to 3 days.' },
        { title: '16. What happens if my Aadhaar details do not match my existing bank accounts?', content: 'If your Aadhaar details do not match your existing bank accounts, those bank accounts will not be used, they will be disassociated with your profile and you will have to add new bank accounts/UPI VPA where the account name matches your Aadhaar name.' },
        { title: '17. What happens if I have done Aadhaar KYC and I already have an existing profile in the system?', content: 'If you have done Aadhaar KYC and you already have an existing profile in the system, you can contact the customer support team. They will conduct a video call to confirm your identity and once verified, they will associate your Aadhar with your profile. The video call will be recorded by the customer support team.' },
        { title: '18. Can I add a bank account with a different name than my Aadhar name?', content: 'No, you cannot add a bank account with a different name than your Aadhar name.' },
        { title: '19. How long does it take for the amount to be credited if the lifetime sell amount is less than 500?', content: 'If the lifetime sell amount is less than 500, the user can provide any bank account details/UPI VPA and the amount will be credited after 48 hours.' },
        { title: '20. What is the wait period for cumulative purchases above Rs. 500?', content: 'If the cumulative purchase is above Rs. 500, a 7-day wait period is applied on any gold bought.' },
        { title: '21. Can a user dispute a PAN that already exists in the system?', content: 'Yes, a user can dispute a PAN that already exists in the system by providing a PAN upload and doing Aadhar KYC. CS will verify and approve after verification, and the PAN will be shifted to the users profile along with Aadhar. Any gold leased on the previous profile will also be shifted to the new profile.' },
    ];

    const handleChangeF3 = (panel) => (_event, isExpanded) => {
        setExpandedFaq03(isExpanded ? panel : false);
    };
    const handleLoadMore3 = () => {
        setVisibleCount03((prevCount) => prevCount + 4);
    };

    const [visibleCount04, setVisibleCount04] = useState(4);
    const [expandedFaq04, setExpandedFaq04] = useState('panelF0');
    const accordions4 = [
        { title: '1. What is a Gold Savings Plan?', content: 'A Gold Savings Plan is a systematic savings tool that allows customers to accumulate gold by choosing a fixed instalment amount on a weekly/monthly basis for a selected duration. Customers can accumulate gold and later use it to exchange gold for jewellery on SafeGold.' },
        { title: '2. Can you choose multiple Gold Savings Plans?', content: 'Yes, you can choose more than 1 Gold Savings Plan by adding individual SIP details for each.' },
        { title: '3. Can you cancel a Gold Savings Plan?', content: 'Yes, you can cancel a Gold Savings Plan, post which you will not be charged for the next instalment.' },
        { title: '4. Can you make changes to an existing Gold Savings Plan?', content: 'Yes, you can make changes to your Gold Savings Plan in two ways – either increase the instalment amount or increase the duration of the plan. However, you cannot decrease the instalment amount, or change the frequency of the instalment.' },
        { title: '5. Is there a minimum amount for the instalment one can make for a Gold Savings Plan?', content: 'You can choose a minimum amount of 100 or higher for the Gold Savings Plan.' },
        { title: '6. Which are the different methods of payments for a Gold Savings Plan?', content: 'You can opt for an e-mandate from the accepted banks list to pay SIP amount.' },
        { title: '7. How does one know when the e-mandate has been verified?', content: 'It takes up to 5 working days for the bank to verify your e-mandate. Once an e-mandate is verified we will send you a SMS to intimate you that it is confirmed and the savings plan is now active.' },
        { title: '8. What will happen if my bank account has insufficient funds when my SIP payment is due?', content: 'For an SIP (Weekly or Monthly) our system triggers a payment on the SIP date. In case the transaction fails, including due to insufficient balance in the account, we will make two consecutive retries in the following days. After 3 failed attempts, the SIP payment for that particular week or month is skipped. SafeGold does not charge users for failed transactions, but we urge users to maintain sufficient balance in their bank account to avoid bank bounce charges.' },
    ];

    const handleChangeF4 = (panel) => (_event, isExpanded) => {
        setExpandedFaq04(isExpanded ? panel : false);
    };
    const handleLoadMore4 = () => {
        setVisibleCount04((prevCount) => prevCount + 4);
    };

    const [visibleCount05, setVisibleCount05] = useState(4);
    const [expandedFaq05, setExpandedFaq05] = useState('panelF0');
    const accordions5 = [
        { title: '1. How does the gold get delivered to me?', content: 'You can request for all or part of your gold holding, from 0.5 gm (and multiples thereof). You can select the denomination of the gold you wish to withdraw from the available options of coins. Each coin will have different making charges, based on the weight and design of the coin. You must confirm the transaction by paying the additional making and delivery charge. Your gold product will be delivered at your notified address by a logistics provider arranged by the Company, and you will receive a confirmation on your registered mobile number. You will receive a dispatch confirmation and the waybill number of the courier company which will allow you track your package online.' },
        { title: '2. Is it possible to store my withdrawn gold coins in safe custody and have them delivered later?', content: 'Once you place a delivery request, you have to accept the delivery made to you. It cannot be made at a later date.' },
        { title: '3. Can I change my delivery address?', content: 'Yes, you can opt to have your gold delivered at an address other than that mentioned at the time of registration. However, this may be subject to verification of address change, as per the norms established by the distributor.' },
        { title: '4. Where is delivery of Gold available?', content: 'SafeGold delivers across India, through partnerships with secure logistics providers. We maintain a list of serviceable pin codes, and prior to making any purchase you will be informed whether you pin code is serviceable for delivery. Depending on the Distributor platform however, you can still choose to have your gold delivered to an address that is different from the one entered at the time of registration. This may be subject to verification by our Partners.' },
        { title: '5. Are there any charges for delivery of gold?', content: 'Yes, you are required to pay a minting charge at the time of requesting for delivery. We deliver your gold to you in the form of assay certified coins, in tamper evident packaging, using secure logistics providers. The charge covers the cost of minting, packaging, insurance, assay certification and delivery to you.' },
        { title: '6. How does SafeGold ensure secured delivery of gold?', content: `The delivery of your gold coins and bars is fully insured. We've partnered with the best delivery partners in the country to ensure your gold is in safe hands right from the locker to your home. To ensure the quality of the gold coin and bars delivered to you - we have our products independently assessed and assay certified to ensure zero negative weight tolerance and promised purity.` }
    ];

    const handleChangeF5 = (panel) => (_event, isExpanded) => {
        setExpandedFaq05(isExpanded ? panel : false);
    };
    const handleLoadMore5 = () => {
        setVisibleCount05((prevCount) => prevCount + 4);
    };

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    var settings01 = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const formatInitialGoldNumber = (num) => {
        if (num === '') return '';
        const parts = num.toString().split('.');
        const integerPart = parts[0].slice(0, 2);
        const decimalPart = parts[1] ? parts[1].slice(0, 4) : '';
        return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
    };
    const initialTime = 300;
    const [unit, setUnit] = useState('rupees');
    const [timeLeft, setTimeLeft] = useState(null);
    const [sliderPosition, setSliderPosition] = useState(100);

    // useEffect(() => {
    //     const timer = setInterval(() => {
    //         setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    //         setSliderPosition((prevPosition) => (prevPosition > 0 ? (timeLeft / 173) * 100 : 0));
    //     }, 1000);
    //     return () => clearInterval(timer);
    // }, [timeLeft]);

    // const formatTime = (seconds) => {
    //     const mins = Math.floor(seconds / 60);
    //     const secs = seconds % 60;
    //     return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
    // };

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft((prevTime) => {
                const newTime = prevTime > 0 ? prevTime - 1 : initialTime;
                setSliderPosition((newTime / initialTime) * 100);
                return newTime;
            });
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    const formatTime = (seconds) => {
        const mins = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
    };

    const handleAmountChange = (e) => {

        const { value } = e.target;

        const regex = /^\d{0,6}(\.\d{0,2})?$/;
        if (regex.test(value) || value === '') {
            setAmount(value);
        }
    };

    const handleGoldChange = (e) => {
        const { value } = e.target;

        const regex = /^\d{0,2}(\.\d{0,4})?$/;
        if (regex.test(value) || value === '') {
            setGold(value);
        }
    };

    const handleUnitChange = (unit) => {
        setUnit(unit);
    };

    const formatNumber = (num, limit) => {
        if (Number.isInteger(num)) {
            return num.toString();
        } else {
            return num.toFixed(limit).replace(/\.?0+$/, '');
        }
    };


    return (
        <>
            {/* Home Save Money start start */}
            <div className="home" id="home">
                <div className="hero-intro">
                    <div data-aos="fade-right" data-aos-duration="1000">
                    <h1><span className="save-money-black-text"><img src={assets.start} /> Save Smarter with <br /> </span> Digital Gold <span className="save-money">  from <span className="ten-number-text"> ₹10</span></span>. </h1>
                    <p className="automatic">Simple, Secure, and Smart Investing! <img style={{ marginTop: '-10px', marginLeft: '20px', width: '50px' }} src={assets.start1} /> </p>
                    </div>
                    <div className="hero-intro-imgs">
                        <img src={assets.hand_coins} className="animated" alt="" />
                    </div>
                    {/* blur divs */}
                    <div className="blur" style={{ background: 'rgb(252 226 124)' }}></div>
                </div>
            </div>
            {/* Home Save Money start end */}

            {/* digital gold start */}
            <div className="digital-gold" data-aos="zoom-in" data-aos-duration="1000">
                <div className='main-text'>
                    <div className="digital-boxs">
                        <h6>The Tradition of Digital Gold</h6>
                    </div>
                    <div className='main-par'>
                        <p>With EG Gold, participate in the timeless tradition of saving in <span style={{ color: '#CA981D', fontWeight: '600' }}> 24 Karat gold  </span> at <span style={{ color: '#004E42', fontWeight: '600' }}> 99.5% purity</span> , ensuring wealth and security for your family.</p>
                    </div>
                </div>
            </div>
            <div className="blur-left" style={{ background: 'rgb(252 226 124)' }}></div>
            {/* digital gold end */}

            {/* how it works start */}
            <div className="how-it-work">
                <p>How Does It Work?</p>
                <h1> <span style={{ color: '#004E42' }}> Choose Your </span> Saving Method</h1>
                <div className="work-content" >
                    <div className="work-left" data-aos="zoom-in" data-aos-duration="1000">
                        <Accordion expanded={expanded === 'panel1'} onClick={(e) => { setExpanded('panel1'); setImg("img1") }} style={{ boxShadow: 'none' }} >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            > Digital Saving
                            </AccordionSummary>
                            <AccordionDetails style={{ fontSize: '18px', fontWeight: '400' }}>
                            Set a daily amount and turn on autosave. Watch your savings grow effortlessly.
                            </AccordionDetails>
                            <AccordionActions className="learn-boxs" style={{ float: 'left' }}>
                                <Button id="learn_more">Learn More</Button>
                                <h6 className="watch-video"> <PlayCircleOutlineIcon style={{ marginRight: '5px' }} /> Watch Video</h6>
                            </AccordionActions>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel2'} onClick={(e) => { setExpanded('panel2'); setImg("img2") }} style={{ boxShadow: 'none' }} >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                            >
                                Save via Round-Offs
                            </AccordionSummary>
                            <AccordionDetails style={{ fontSize: '18px', fontWeight: '400' }}>
                            Save as you spend. EG Gold rounds up your spare change and converts it into gold.
                            </AccordionDetails>
                            <AccordionActions className="learn-boxs" style={{ float: 'left' }}>
                                <Button id="learn_more">Learn More</Button>
                                <h6 className="watch-video"> <PlayCircleOutlineIcon style={{ marginRight: '5px' }} /> Watch Video</h6>
                            </AccordionActions>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel3'} onClick={(e) => { setExpanded('panel3'); setImg("img3") }} style={{ boxShadow: 'none' }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel3-content"
                                id="panel3-header"
                            >Manual Savings
                            </AccordionSummary>
                            <AccordionDetails style={{ fontSize: '18px', fontWeight: '400' }}>
                            Save on your own terms. You control the amount and frequency of your manual deposits into gold.
                            </AccordionDetails>
                            <AccordionActions className="learn-boxs" style={{ float: 'left' }}>
                                <Button id="learn_more">Learn More</Button>
                                <h6 className="watch-video"> <PlayCircleOutlineIcon style={{ marginRight: '5px' }} /> Watch Video</h6>
                            </AccordionActions>
                        </Accordion>
                    </div>
                    <div className="work-right animated">
                        {img === "img1" && <img src={assets.daily_saving} alt="" />}
                        {img === "img2" && <img src={assets.hand_putting} alt="" />}
                        {img === "img3" && <img src={assets.manual_saving} alt="" />}
                    </div>
                </div>
                <div className="s-blur2" style={{ background: 'rgb(252 226 124)' }}></div>
            </div>
            {/* how it works end */}

            {/* hot-eg-gold start */}
            <div className="hot-eg-gold">
                <h1>What's Trending on <span style={{ color: '#CB991D' }}> EG Gold?</span></h1>
                {isMobile ? (
                    <div className="carousel" data-aos="zoom-in" data-aos-duration="1000">
                        <Slider {...settings}>
                            <div className="hot-eg-gold-left">
                                <Card sx={{ borderRadius: '25px', boxShadow: 'none' }}>
                                    <CardActionArea>
                                        <CardMedia component="img" image={assets.best_reward} alt="save money" />
                                    </CardActionArea>
                                </Card>
                            </div>
                            <div className="hot-eg-gold-center">
                                <Card sx={{ borderRadius: '25px', boxShadow: 'none' }}>
                                    <CardActionArea>
                                        <CardMedia component="img" image={assets.best_reward1} alt="save money" />
                                    </CardActionArea>
                                </Card>
                            </div>
                            <div className="hot-eg-gold-right">
                                <Card sx={{ borderRadius: '25px', boxShadow: 'none' }}>
                                    <CardActionArea>
                                        <CardMedia component="img" image={assets.best_reward2} alt="save money" />
                                    </CardActionArea>
                                </Card>
                            </div>
                        </Slider>
                    </div>
                ) : (
                    <div className="hot-reward">
                        <div className="hot-eg-gold-content">
                            <div className="hot-eg-gold-left" data-aos="zoom-in" data-aos-duration="1000">
                                <Card sx={{ borderRadius: '25px', boxShadow: 'none' }}>
                                    <CardActionArea>
                                        <CardMedia component="img" image={assets.best_reward} alt="save money" />
                                    </CardActionArea>
                                </Card>
                            </div>
                            <div className="hot-eg-gold-center" data-aos="zoom-in" data-aos-duration="2000">
                                <Card sx={{ borderRadius: '25px', boxShadow: 'none' }}>
                                    <CardActionArea>
                                        <CardMedia component="img" image={assets.best_reward1} alt="save money" />
                                    </CardActionArea>
                                </Card>
                            </div>
                            <div className="hot-eg-gold-right" data-aos="zoom-in" data-aos-duration="3000">
                                <Card sx={{ borderRadius: '25px', boxShadow: 'none' }}>
                                    <CardActionArea>
                                        <CardMedia component="img" image={assets.best_reward2} alt="save money" />
                                    </CardActionArea>
                                </Card>
                            </div>
                        </div>
                    </div>
                )}
                <div className="blur-hot" style={{ background: 'rgb(252 226 124)' }}></div>
            </div>
            {/* hot-eg-gold end */}

            {/* saving-eg-gold start */}
            <div className="saving-eg-gold" id="features">
                <h1>Saving Made Simple with <span style={{ color: '#CB991D' }}>EG-Gold</span> </h1>
                {isMobile ? (
                    <div className="carousel" data-aos="zoom-in" data-aos-duration="1000">
                        <Slider {...settings01}>
                            <div className="saving-eg-gold-left">
                                <div className="automated-icon">
                                    <img src={assets.automated_icon} />
                                </div>
                                <img src={assets.automated} />
                                <h3>Automated Savings System</h3>
                                <p>Save effortlessly every day. Set your amount and stay consistent.</p>
                            </div>
                            <div className="saving-eg-gold-left">
                                <div className="automated-icon">
                                    <img src={assets.many_save_icon} />
                                </div>
                                <img src={assets.many_save} />
                                <h3>Variety of Saving Plans</h3>
                                <p>Save according to your preference: daily, weekly, or monthly.</p>
                            </div>
                            <div className="saving-eg-gold-left">
                                <div className="automated-icon">
                                    <img src={assets.withdraw_icon} />
                                </div>
                                <img src={assets.withdraw} />
                                <h3>Flexible Withdrawals</h3>
                                <p>Pause or cancel your savings plan at any time. Withdraw without any fuss.</p>
                            </div>
                        </Slider>
                    </div>
                ) : (
                    <div className="saving-eg-gold-content">
                        <Card sx={{boxShadow: 'none', background: 'transparent'}} data-aos="flip-left" data-aos-duration="3000">
                        <div className="saving-eg-gold-left">
                            <div className="automated-icon">
                                <img src={assets.automated_icon} />
                            </div>
                            <img src={assets.automated} />
                            <h3>Automated Savings System</h3>
                            <p>Save effortlessly every day. Set your amount and stay consistent.</p>
                        </div>
                        </Card>
                        <Card sx={{boxShadow: 'none', background: 'transparent'}} data-aos="flip-left" data-aos-duration="3000">
                        <div className="saving-eg-gold-left">
                            <div className="automated-icon">
                                <img src={assets.many_save_icon} />
                            </div>
                            <img src={assets.many_save} />
                            <h3>Variety of Saving Plans</h3>
                            <p>Save according to your preference: daily, weekly, or monthly.</p>
                        </div>
                        </Card>
                        <Card sx={{boxShadow: 'none', background: 'transparent'}} data-aos="flip-left" data-aos-duration="3000">
                        <div className="saving-eg-gold-left">
                            <div className="automated-icon">
                                <img src={assets.withdraw_icon} />
                            </div>
                            <img src={assets.withdraw} />
                            <h3>Flexible Withdrawals</h3>
                            <p>Pause or cancel your savings plan at any time. Withdraw without any fuss.</p>
                        </div>
                        </Card>
                    </div>
                )}
            </div>
            {/* saving-eg-gold end */}

            {/* partners start */}
            <div className="partners">
                <h1>Our Trusted <span style={{ color: '#CB991D' }}> Partners </span></h1>
                <div className="partners-content" data-aos="zoom-in" data-aos-duration="1000">
                    <div className="partners-left">
                        <img src={assets.safegold} className="partners-imgs" />
                    </div>
                    <div className="partners-center">
                        <img src={assets.safegold} className="partners-imgs" />
                    </div>
                    <div className="partners-right">
                        <img src={assets.safegold} className="partners-imgs" />
                    </div>
                </div>
            </div>
            {/* partners end */}

            {/* calculate start */}
            <div className="calculate">
                <div className="calculate-content">
                    <div className="calculate-left" data-aos="zoom-in-right" data-aos-duration="3000">
                        <h1>Curious About Your <span style={{ color: '#CB991D' }}> Gold </span> Savings <span style={{ color: '#CB991D' }}> Performance? </span></h1>
                        <button className="calculate-now">Calculate Now</button>
                    </div>
                    <div className="calculate-right" data-aos="zoom-in-left" data-aos-duration="3000">
                        <div className="container">
                            <div className="tabs">
                                <button className={tab === 'buy' ? 'active' : ''} onClick={() => setTab('buy')}>
                                    Buy
                                </button>
                                <button className={tab === 'sell' ? 'active' : ''} onClick={() => setTab('sell')}>
                                    Sell
                                </button>
                            </div>
                            <div className="golds-amounts">
                                <h2>Enter Gold Amount</h2>
                                <div className="input-container">
                                    <button className={unit === 'rupees' ? 'active' : ''} onClick={() => handleUnitChange('rupees')}>
                                        In Rupees
                                    </button>
                                    <button className={unit === 'gram' ? 'active' : ''} onClick={() => handleUnitChange('gram')}>
                                        In Gram
                                    </button>
                                </div>
                                <div className="input-box">
                                    {unit === "rupees" ? (
                                    <>
                                        {" "}
                                        <div className="rupees-icons">₹</div>
                                        <input
                                        type="text"
                                        value={amount}
                                        onChange={handleAmountChange}
                                        />
                                        {/* <span>{unit === 'rupees' ? `${(amount/price).toFixed(4)}gm` : '₹'}</span> */}
                                        {/* <span>{(amount/price).toFixed(4)}gm</span> */}
                                        {/* <span>{formatNumber((amount/price), 4)}gm</span> */}
                                        {tab === "buy" ? (
                                        //   <span>{amount / price}gm</span>
                                        <span>{formatNumber((amount/amountBuy), 4)}gm</span>
                                        ) : (
                                            <span>{formatNumber((amount/sellPrice), 4)}gm</span>
                                        //   <span>{amount / sellPrice}gm</span>
                                        )}
                                    </>
                                    ) : (
                                    <>
                                        {" "}
                                        <div style={{ marginLeft: "5px" }}></div>
                                        <input
                                        type="text"
                                        placeholder="0"
                                        value={gold}
                                        onChange={handleGoldChange}
                                        />
                                        {/* <span>{((gold*price)/10).toFixed(2)} ₹</span> */}
                                        {tab === "buy" ? (
                                            <span>{formatNumber((gold*goldBuy), 2)} ₹</span>
                                        ) : (
                                            <span>{formatNumber((gold*sellPrice), 2)} ₹</span>
                                        )}
                                    </>
                                    )}
                                </div>
                                {tab === "buy" ? (
                                    <p className="buy-gst">Buy Price: Rs. 5999.45/gm + 3% GST</p>
                                ) : <div style={{ marginBottom: '20px' }}></div> }
                                {/* <p className="buy-gst">Buy Price: Rs. 5999.45/gm + 3% GST</p> */}
                                <div className="live-price">
                                    <span className="live-indicator"><span class="blink"> ● </span> LIVE</span> 
                                    {tab === "buy" ? (
                                        <span>Price: {price}/gm</span>
                                    ) : (
                                        <span>Price: {sellPrice}/gm</span>
                                    )}
                                </div>
                                <span style={{ fontSize: '15px', fontWeight: '600' }}>Valid for: {formatTime(timeLeft)}</span>
                                <div className="slider-container" style={{ marginTop: '5px' }}>
                                    <div className="slider-bar" style={{ width: `${sliderPosition}%` }}>
                                    </div>
                                </div>
                                {tab === 'buy' ? (<button className="buy-button">Buy Now</button>) : (<button className="buy-button1">Sell Now</button>)}

                            </div>
                        </div>
                    </div>
                </div>
                <div className="blur-calculate" style={{ background: 'rgb(252 226 124)' }}></div>
            </div>
            {/* calculate end */}

            {/* Save every day, withdraw any day start */}
            <div className="withdraw-save">
                <h1>Save Daily, Withdraw <span style={{ color: '#CB991D' }}> Anytime </span></h1>
                <div className="withdraw-save-content" data-aos="zoom-in" data-aos-duration="3000">
                    <div className="withdraw-save-left">
                        <img src={assets.golds_icon} />
                        <p>Gold Delivered to Your Doorstep</p>
                    </div>
                    <div className="withdraw-save-center">
                        <p>OR</p>
                    </div>
                    <div className="withdraw-save-right">
                        <img src={assets.gold_coins} />
                        <p>Sell Your Gold for Cash &nbsp;&nbsp;&nbsp;&nbsp;</p>
                    </div>
                </div>
                {/* <button className="learn-more">Learn More</button> */}
                <div className="blur-withdraw" style={{ background: 'rgb(252 226 124)' }}></div>
            </div>
            {/* Save every day, withdraw any day end */}

            {/* Meet the people start */}
            {/* <div className="meet-people">
                <h1> Discover How We’ve Made a  <span style={{ color: '#CB991D' }}> Difference</span>.</h1>
                <div className="carousel">
                    <Slider {...settings}>
                        <div className="box">
                            <img src={assets.vid_img} style={{ borderRadius: '20px' }} />
                            <h3>Radha</h3>
                            <p>QA Manager</p>
                        </div>
                        <div className="box">
                            <img src={assets.vid_img} style={{ borderRadius: '20px' }} />
                            <h3>Radha</h3>
                            <p>QA Manager</p>
                        </div>
                        <div className="box">
                            <img src={assets.vid_img} style={{ borderRadius: '20px' }} />
                            <h3>Radha</h3>
                            <p>QA Manager</p>
                        </div>
                        <div className="box">
                            <img src={assets.vid_img} style={{ borderRadius: '20px' }} />
                            <h3>Radha</h3>
                            <p>QA Manager</p>
                        </div>
                        <div className="box">
                            <img src={assets.vid_img} style={{ borderRadius: '20px' }} />
                            <h3>Radha</h3>
                            <p>QA Manager</p>
                        </div>
                    </Slider>
                </div>
            </div> */}
            {/* Meet the people end */}

            {/* EG in News start */}
            <div className="eg-news">
                <p className="user-testimonial">User Testimonial </p>
                <h1> What Our Users Are  <span style={{ color: '#CB991D' }}> Saying </span></h1>
                <div className="carousel" data-aos="zoom-in" data-aos-duration="1000">
                    <Slider {...settings01}>
                        <div className="boxs">
                            {/* <img src={assets.layer} /> */}
                            <p>“EG Gold has made saving so easy and stress-free. I love how I can invest a portion of my salary into digital gold with just a few clicks. It's a great feeling knowing I'm securing my future bit by bit. Highly recommend it!”</p>
                            <h4>Anjali Rathore</h4>
                        </div>
                        <div className="boxs">
                            {/* <img src={assets.layer} /> */}
                            <p>“I was always worried about how to start saving effectively, but EG Gold has been a game-changer. The platform is user-friendly and secure, and I've already seen my savings grow. It's comforting to know that I'm investing in something as reliable as gold.”</p>
                            <h4>Vikram Singh</h4>
                        </div>
                        <div className="boxs">
                            {/* <img src={assets.layer} /> */}
                            <p>“EG Gold has helped me build a saving habit effortlessly. I appreciate the transparency and the ease of use. Being able to watch my investments grow in real time gives me peace of mind. This platform is perfect for anyone looking to secure their financial future.”</p>
                            <h4>Neha Malhotra</h4>
                        </div>
                        <div className="boxs">
                            {/* <img src={assets.layer} /> */}
                            <p>“EG Gold has made saving so easy and stress-free. I love how I can invest a portion of my salary into digital gold with just a few clicks. It's a great feeling knowing I'm securing my future bit by bit. Highly recommend it!”</p>
                            <h4>Anjali Rathore</h4>
                        </div>
                        <div className="boxs">
                            {/* <img src={assets.layer} /> */}
                            <p>“I was always worried about how to start saving effectively, but EG Gold has been a game-changer. The platform is user-friendly and secure, and I've already seen my savings grow. It's comforting to know that I'm investing in something as reliable as gold.”</p>
                            <h4 style={{color: '#000'}}>Vikram Singh</h4>
                        </div>
                    </Slider>
                </div>
            </div>
            {/* EG in News end */}

            {/* Our control of knowledge start */}
            {/* <div className="control-knowledge" id="blog">
                <p>Learn and Grow</p>
                <h1> Your Guide to Smart <span style={{ color: '#CB991D' }}> Saving and Investing </span> </h1>
                {isMobile ? (
                    <div className="carousel">
                        <Slider {...settings01}>
                            <div className="control-knowledge-left">
                                <Card sx={{ borderRadius: '20px' }}>
                                    <CardActionArea>
                                        <CardMedia component="img" image={assets.seve_money} alt="seve money" />
                                        <CardContent sx={{ background: '#fff' }}>
                                            <h4>Investment in Gold: Discover ways to shine your portfolio brighter!</h4>
                                            <p>5 min read  <b> • Beginner </b> </p>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </div>
                            <div className="control-knowledge-center">
                                <Card sx={{ borderRadius: '20px' }}>
                                    <CardActionArea>
                                        <CardMedia component="img" image={assets.seve_money} alt="seve money" />
                                        <CardContent sx={{ background: '#fff' }}>
                                            <h4>Gold as a Safe Haven Investment: Lessons from Economic Crises</h4>
                                            <p>4 min read  <b> • Intermediate </b> </p>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </div>
                            <div className="control-knowledge-right">
                                <Card sx={{ borderRadius: '20px'}}>
                                    <CardActionArea>
                                        <CardMedia component="img" image={assets.seve_money} alt="seve money" />
                                        <CardContent sx={{ background: '#fff' }}>
                                            <h4>The Future of Gold: Emerging Trends and Technologies</h4>
                                            <p>6 min read  <b> • Advance </b> </p>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </div>
                        </Slider>
                    </div>) : (
                    <div className="control-knowledge-content">
                        <div className="control-knowledge-left">
                            <Card sx={{ borderRadius: '20px' }}>
                                <CardActionArea>
                                    <CardMedia component="img" image={assets.seve_money} alt="seve money" />
                                    <CardContent sx={{ background: '#fff' }}>
                                        <h4>Investment in Gold: Discover ways to shine your portfolio brighter!</h4>
                                        <p>4 min read  <b> • Beginner  </b> </p>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </div>
                        <div className="control-knowledge-center">
                            <Card sx={{ borderRadius: '20px' }}>
                                <CardActionArea>
                                    <CardMedia component="img" image={assets.seve_money} alt="seve money" />
                                    <CardContent sx={{ background: '#fff' }}>
                                        <h4>Gold as a Safe Haven Investment: Lessons from Economic Crises</h4>
                                        <p>5 min read  <b> • Intermediate </b> </p>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </div>
                        <div className="control-knowledge-right">
                            <Card sx={{ borderRadius: '20px' }}>
                                <CardActionArea>
                                    <CardMedia component="img" image={assets.seve_money} alt="seve money" />
                                    <CardContent sx={{ background: '#fff' }}>
                                        <h4>The Future of Gold: Emerging Trends and Technologies</h4>
                                        <p>6 min read  <b> • Advance </b> </p>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </div>
                    </div>
                )}

                <button className="view-blogs">View all Blogs</button>
            </div> */}
            {/* Our control of knowledge end */}

            {/* All your questions, answered start */}
            <div className="questions-answered" id="faq">
                <h1>Got Questions? We’ve <span style={{ color: '#CB991D' }}> Got Answers </span></h1>
                <div className="questions-answered-content" data-aos="fade-up" data-aos-duration="3000">
                    <Box>
                        <Box className="explore-menu-list" sx={{ borderBottom: 1, borderColor: 'divider', }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="General" {...a11yProps(0)} />
                                <Tab label="Buy" {...a11yProps(1)} />
                                <Tab label="Lease" {...a11yProps(2)} />
                                <Tab label="Sell" {...a11yProps(3)} />
                                <Tab label="SIP" {...a11yProps(4)} />
                                <Tab label="Delivery" {...a11yProps(5)} />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                            <div>
                                {accordions.slice(0, visibleCount).map((accordion, index) => (
                                    <Accordion className="faq-ans"
                                        key={index}
                                        expanded={expandedFaq === `panelF${index}`}
                                        onChange={handleChangeF(`panelF${index}`)}
                                        sx={{ background: '#FFF3C2', boxShadow: 'none' }}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ArrowDropDownIcon />}
                                            aria-controls="panel2-content"
                                            id="panel2-header"
                                        >
                                            <Typography style={{ fontWeight: '600' }}>{accordion.title}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{ marginLeft: '15px' }}>
                                            <Typography>{accordion.content}</Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </div>
                            {/* <div>
                                    <Accordion expanded={faqs === 'faq1'} onClick={(e) => { setFaqs('faq1');}} className="faq-ans" sx={{background: '#FFF3C2', boxShadow: 'none'}}>
                                        <AccordionSummary
                                        expandIcon={<ArrowDropDownIcon />}
                                        aria-controls="panel2-content"
                                        id="panel2-header"
                                        >
                                        <Typography>1. Is EG App safe to use?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        <Typography sx={{marginLeft: '15px'}}>
                                            EG-Gold app is 100% safe and secure to use for your Daily Savings & Investments in Gold. It is powered by SafeGold and all payments happen over secure banking networks.
                                        </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </div> */}
                            <div className="load-more-button">
                                {visibleCount < accordions.length && (
                                    <button className="load-more" onClick={handleLoadMore} variant="contained">
                                        Load More
                                    </button>
                                )}
                            </div>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            <div>
                                {accordions1.slice(0, visibleCount01).map((accordion, index) => (
                                    <Accordion className="faq-ans"
                                        key={index}
                                        expanded={expandedFaq01 === `panelF${index}`}
                                        onChange={handleChangeF1(`panelF${index}`)}
                                        sx={{ background: '#FFF3C2', boxShadow: 'none' }}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ArrowDropDownIcon />}
                                            aria-controls="panel2-content"
                                            id="panel2-header"
                                        >
                                            <Typography style={{ fontWeight: '600' }}>{accordion.title}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{ marginLeft: '15px' }}>
                                            <Typography>{accordion.content}</Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </div>
                            <div className="load-more-button">
                                {visibleCount01 < accordions1.length && (
                                    <button className="load-more" onClick={handleLoadMore1} variant="contained">
                                        Load More
                                    </button>
                                )}
                            </div>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={2}>
                            <div>
                                {accordions2.slice(0, visibleCount02).map((accordion, index) => (
                                    <Accordion className="faq-ans"
                                        key={index}
                                        expanded={expandedFaq02 === `panelF${index}`}
                                        onChange={handleChangeF2(`panelF${index}`)}
                                        sx={{ background: '#FFF3C2', boxShadow: 'none' }}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ArrowDropDownIcon />}
                                            aria-controls="panel2-content"
                                            id="panel2-header"
                                        >
                                            <Typography style={{ fontWeight: '600' }}>{accordion.title}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{ marginLeft: '15px' }}>
                                            <Typography>{accordion.content}</Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </div>
                            <div className="load-more-button">
                                {visibleCount02 < accordions2.length && (
                                    <button className="load-more" onClick={handleLoadMore2} variant="contained">
                                        Load More
                                    </button>
                                )}
                            </div>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={3}>
                            <div>
                                {accordions3.slice(0, visibleCount03).map((accordion, index) => (
                                    <Accordion className="faq-ans"
                                        key={index}
                                        expanded={expandedFaq03 === `panelF${index}`}
                                        onChange={handleChangeF3(`panelF${index}`)}
                                        sx={{ background: '#FFF3C2', boxShadow: 'none' }}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ArrowDropDownIcon />}
                                            aria-controls="panel2-content"
                                            id="panel2-header"
                                        >
                                            <Typography style={{ fontWeight: '600' }}>{accordion.title}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{ marginLeft: '15px' }}>
                                            <Typography>{accordion.content}</Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </div>
                            <div className="load-more-button">
                                {visibleCount03 < accordions3.length && (
                                    <button className="load-more" onClick={handleLoadMore3} variant="contained">
                                        Load More
                                    </button>
                                )}
                            </div>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={4}>
                            <div>
                                {accordions4.slice(0, visibleCount04).map((accordion, index) => (
                                    <Accordion className="faq-ans"
                                        key={index}
                                        expanded={expandedFaq04 === `panelF${index}`}
                                        onChange={handleChangeF4(`panelF${index}`)}
                                        sx={{ background: '#FFF3C2', boxShadow: 'none' }}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ArrowDropDownIcon />}
                                            aria-controls="panel2-content"
                                            id="panel2-header"
                                        >
                                            <Typography style={{ fontWeight: '600' }}>{accordion.title}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{ marginLeft: '15px' }}>
                                            <Typography>{accordion.content}</Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </div>
                            <div className="load-more-button">
                                {visibleCount04 < accordions4.length && (
                                    <button className="load-more" onClick={handleLoadMore4} variant="contained">
                                        Load More
                                    </button>
                                )}
                            </div>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={5}>
                            <div>
                                {accordions5.slice(0, visibleCount05).map((accordion, index) => (
                                    <Accordion className="faq-ans"
                                        key={index}
                                        expanded={expandedFaq05 === `panelF${index}`}
                                        onChange={handleChangeF5(`panelF${index}`)}
                                        sx={{ background: '#FFF3C2', boxShadow: 'none' }}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ArrowDropDownIcon />}
                                            aria-controls="panel2-content"
                                            id="panel2-header"
                                        >
                                            <Typography style={{ fontWeight: '600' }}>{accordion.title}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{ marginLeft: '15px' }}>
                                            <Typography>{accordion.content}</Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </div>
                            <div className="load-more-button">
                                {visibleCount05 < accordions5.length && (
                                    <button className="load-more" onClick={handleLoadMore5} variant="contained">
                                        Load More
                                    </button>
                                )}
                            </div>
                        </CustomTabPanel>
                    </Box>
                </div>
            </div>
            {/* All your questions, answered end */}
        </>
    );
};

export default Home;


