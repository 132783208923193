import eg_logo from "./eg_logo.png";
import eg_logo_text from "./eg_logo_text.png";
import eg_logo_footer from "./eg_logo_footer.png";
import hand_coins from "./hand_coins.png";
import google from "./google.png";
import app_store from "./app_store.png";
import coins from "./coins.png";
import upi_icon from "./upi_icon.png";
import star_icon from "./star_icon.png";
import user_review from "./user_review.png";
import gold_icon from "./gold_icon.png";
import priv_icon from "./priv_icon.png";
import best_reward from "./best_reward.png";
import best_reward1 from "./best_reward1.png";
import best_reward2 from "./best_reward2.png";
import automated from "./automated.png";
import many_save from "./many_save.png";
import withdraw from "./withdraw.png";
import many_save_icon from "./many_save_icon.png";
import withdraw_icon from "./withdraw_icon.png";
import automated_icon from "./automated_icon.png";
import safegold from "./safegold.png";
import golds_icon from "./golds_icon.png";
import gold_coins from "./gold_coins.png";
import vid_img from "./vid_img.png";
import layer from "./layer.png";
import seve_money from "./seve_money.png";
import start from "./start.png";
import start1 from "./start1.png";
import hand_putting from "./hand_putting.png";
import daily_saving from "./daily_saving.png";
import manual_saving from "./manual_saving.png";



export const assets = {
    eg_logo,
    eg_logo_text,
    eg_logo_footer,
    hand_coins,
    google,
    app_store,
    coins,
    upi_icon,
    star_icon,
    user_review,
    gold_icon,
    priv_icon,
    best_reward,
    best_reward1,
    best_reward2,
    automated,
    many_save,
    withdraw,
    many_save_icon,
    withdraw_icon,
    automated_icon,
    safegold,
    golds_icon,
    gold_coins,
    vid_img,
    layer,
    seve_money,
    start,
    start1,
    hand_putting,
    daily_saving,
    manual_saving,
}