import React from "react";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
    return (
        <>
            <div className="privacy-policy">
                <div className="privacy-policy-intro">
                    <h1>Privacy <span style={{ color: '#CB991D' }}> Policy </span></h1>
                    <hr />
                    <div className="privacy-policy-par">
                        <p>We JPRWEBS COMMUNICATIONS PVT LTD. , with corporate office at C26D, RIICCO Industrial Area, Malviya Nagar, Jaipur, Rajasthan - 302017 hereinafter referred to as EG Gold. At EG Gold, we value your trust & respect your privacy. This Privacy Policy provides you with details about the manner in which your data is collected, stored & used by us. You are advised to read this Privacy Policy carefully. By visiting EG Gold’s website/WAP site/applications you expressly give us consent to use & disclose your personal information in accordance with this Privacy Policy. If you do not agree to the terms of the policy, please do not use or access EG Gold website, WAP site or mobile applications. Note: Our privacy policy may change at any time without prior notification. To make sure that you are aware of any changes, kindly review the policy periodically. This Privacy Policy shall apply uniformly to EG Gold desktop website, EG Gold mobile WAP site &EG Gold mobile applications</p>
                        <h3>General</h3>
                        <p>We will not sell, share or rent your personal information to any 3rd party or use your email address/mobile number for unsolicited emails and/or SMS. Any emails and/or SMS sent by EG Gold will only be in connection with the provision of agreed services & products and this Privacy Policy. Periodically, we may reveal general statistical information about EG Gold & its users, such as number of visitors, number and type of goods and services purchased, etc. We reserve the right to communicate your personal information to any third party that makes a legally-compliant request for its disclosure.</p>
                        <h3>Personal Information</h3>
                        <p>Personal Information means and includes all information that can be linked to a specific individual or to identify any individual, such as name, address, mailing address, telephone number, email ID, credit card number, cardholder name, card expiration date, information about your mobile phone, DTH service, data card, electricity connection, Smart Tags and any details that may have been voluntarily provide by the user in connection with availing any of the services on EG Gold When you browse through EG Gold, we may collect information regarding the domain and host from which you access the internet, the Internet Protocol [IP] address of the computer or Internet service provider [ISP] you are using, and anonymous site statistical data.</p>
                        <h3>Use of Personal Information</h3>
                        <p>We use personal information to provide you with services & products you explicitly requested for, to resolve disputes, troubleshoot concerns, help promote safe services, collect money, measure consumer interest in our services, inform you about offers, products, services, updates, customize your experience, detect & protect us against error, fraud and other criminal activity, enforce our terms and conditions, etc. We also use your contact information to send you offers based on your previous orders and interests. We may occasionally ask you to complete optional online surveys. These surveys may ask you for contact information and demographic information (like zip code, age, gender, etc.). We use this data to customize your experience at EG Gold, providing you with content that we think you might be interested in and to display content according to your preferences.</p>
                        <h3>Cookies</h3>
                        <p>A “cookie” is a small piece of information stored by a web server on a web browser so it can be later read back from that browser. EG Gold uses cookie and tracking technology depending on the features offered. No personal information will be collected via cookies and other tracking technology; however, if you previously provided personally identifiable information, cookies may be tied to such information. Aggregate cookie and tracking information may be shared with third parties.</p>
                        <h3>Links to Other Sites</h3>
                        <p>Our site links to other websites that may collect personally identifiable information about you. EG Gold is not responsible for the privacy practices or the content of those linked websites.</p>
                        <h3>Security</h3>
                        <p>EG Gold has stringent security measures in place to protect the loss, misuse, and alteration of the information under our control. Whenever you change or access your account information, we offer the use of a secure server. Once your information is in our possession we adhere to strict security guidelines, protecting it against unauthorized access.</p>
                        <h3>Consent</h3>
                        <p>By using EG Gold and/or by providing your information, you consent to the collection and use of the information you disclose on EG Gold in accordance with this Privacy Policy, including but not limited to your consent for sharing your information as per this privacy policy.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PrivacyPolicy;