// pages/team.js

import React from "react";

const Teams = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "centre",
                alignItems: "centre",
                height: "100vh",
            }}
        >
            <h1>Welcome to GeeksforGeeks Team</h1>
        </div>
    );
};

export default Teams;