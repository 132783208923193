import React from "react";
import "./About.css";
import { assets } from "../../assets/assets";

const About = () => {
    return (
        <>
            <div className="about">
                <div className="about-intro">
                    <h1>About <span style={{ color: '#CB991D' }}> Us </span></h1>
                    <hr />
                    <div className="about-content">
                        <div className="about-left" data-aos="zoom-in" data-aos-duration="1000">
                            <h3>About Us</h3>
                            <p>Welcome to EG Gold, your trusted partner in building a secure financial future through smart savings by investing in Digital Gold. Our platform is designed to make saving easy, accessible, and rewarding by allowing you to invest in digital gold—a timeless asset that has stood the test of time.</p>
                            <p>Our mission is to empower individuals to take control of their financial future, one step at a time. That's why we've created a user-friendly platform that helps you save effortlessly. With just a few clicks, you can start converting a portion of your salary into digital gold, ensuring that your savings grow steadily and securely.</p>
                            <p>At EG Gold, transparency and trust are at the core of everything we do. We provide real-time updates on your investments and ensure the highest standards of security, so you can rest assured that your savings are in safe hands.</p>
                        </div>
                        <div className="about-right animated">
                            <img src={assets.coins} />
                        </div>   
                    </div>
                </div>
            </div>
        </>
    );
};

export default About;