// pages/events.js

import React from "react";

const Events = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "centre",
                alignItems: "centre",
                height: "100vh",
            }}
        >
            <h1>Welcome to GeeksforGeeks Events</h1>
        </div>
    );
};

export default Events;